<template>
  <v-row>
    <v-col cols="12">
      <v-card style="font-size:.9em;min-height:97px;width:100%;" class="mb-2">
        <v-data-table
          :headers="table_headers"
          :items="desserts"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          class="elevation-1"
          sort-by="valid_from"
          :sort-desc="true"
          :footer-props="{
            'items-per-page-options': [5, 10, 25, 100]
          }"
        >
          <template v-slot:item.sha1="{ item }">
            <CertTile :certificate="item" :local="local"></CertTile>
          </template>

          <template v-slot:item.subject_fqdn.friendly_name="{ item }">
            <Fqdn :value="item.subject_fqdn" size="small"></Fqdn>
            <br />
            <span style="font-size:.8em;font-weight:bold;margin-left:5px;"
              >Issued by</span
            >
            <Fqdn :value="item.issuer_fqdn" size="x-small"></Fqdn>
          </template>

          <template v-slot:item.valid_from="{ item }">
            <span v-if="beforeNow(item.valid_from)">
              {{ item.valid_from | moment("MMM D YYYY") }}
            </span>
            <span v-else style="color:red;font-weight:bold;">
              {{ item.valid_from | moment("MMM D YYYY") }}
            </span>
          </template>

          <template v-slot:item.valid_to="{ item }">
            <span v-if="afterNow(item.valid_to)">
              {{ item.valid_to | moment("MMM D YYYY") }}
            </span>
            <span v-else style="color:red;font-weight:bold;">
              {{ item.valid_to | moment("MMM D YYYY") }}
            </span>
          </template>

          <template v-slot:loading>
            <LoadingSVG />
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import UserService from "../../store/UserService";
import LoadingSVG from "../LoadingSVG";
import moment from "moment";
import CertTile from "../CertTile";
import Fqdn from "../Fqdn";
export default {
  name: "SearchCertificates",
  components: { Fqdn, CertTile, LoadingSVG },
  props: ["data_url", "search"],
  data: function() {
    return {
      local: true,
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: { itemsPerPage: 10 },
      table_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Subject",
          sortable: true,
          value: "subject_fqdn.friendly_name"
        },
        {
          text: "Valid From",
          sortable: true,
          width: 100,
          value: "valid_from"
        },
        {
          text: "Valid To",
          sortable: true,
          width: 100,
          value: "valid_to"
        }
      ],
      small_headers: [
        {
          text: "",
          sortable: false,
          value: "sha1",
          width: 104
        },
        {
          text: "Subject",
          sortable: true,
          value: "subject_fqdn.friendly_name"
        }
      ]
    };
  },
  computed: {},
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    }
  },
  watch: {
    options: {
      handler() {
        let url =
          this.data_url +
          "&page=" +
          this.options.page +
          "&size=" +
          this.options.itemsPerPage;
        if (this.search) {
          url += "&search=" + this.search;
        }
        if (this.options.sortBy) {
          if (this.options.sortDesc[0]) {
            url += "&ordering=-" + this.options.sortBy;
          } else {
            url += "&ordering=" + this.options.sortBy;
          }
        }
        console.log(url);
        UserService.getLocalApi(url).then(response => {
          let items = response.data.results;

          this.totalDesserts = response.data.count;
          this.desserts = items;
          this.loading = false;
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
