<template>
  <table style="width:100%;border:0;" class="pa-0 ma-0">
    <tr class="pa-0 ma-0">
      <td width="50" class="pa-0 ma-0">
        <v-progress-circular
          :size="35"
          :width="6"
          :value="display_uptime"
          :color="computed_color"
          class="ma-2"
          v-if="display_uptime != null"
        >
          <span
            v-if="display_uptime != null"
            style="font-size:.675rem; color:black !important;"
          >
            {{ display_uptime }}</span
          >
          <span v-else>?</span>
        </v-progress-circular>
      </td>
      <td class="pa-0 ma-0">
        <v-lazy>
          <v-text-field
            readonly
            dense
            style="height:24px;font-size:12px;width:100%;"
            ref="textToCopy"
            class="ma-0 pa-0"
            :value="url.uri"
          >
            <template v-slot:append class="ma-0 pa-0">
              <v-btn
                class="ma-0 mr-0 pa-2 ml-1 mb-1"
                color="primary"
                label
                x-small
                text
                outlined
                @click="copyText"
              >
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
              <v-btn
                class="ma-0 mr-0 pa-2 ml-1 mb-1"
                color="primary"
                label
                x-small
                text
                outlined
                :to="`/url/${url.sha1}/`"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-text-field>
        </v-lazy>

        <template v-if="url.is_private">
          <v-btn
            v-if="unavailable"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="red"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> PRIVATE</v-btn
          >
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            x-small
            color="success"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> PRIVATE</v-btn
          >
        </template>

        <template v-if="url.is_public">
          <v-btn
            v-if="display_uptime === null"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="black"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            PUBLIC</v-btn
          >
          <v-btn
            v-else-if="unavailable"
            v-bind="attrs"
            unavailable
            v-on="on"
            x-small
            color="red"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-down-thick</v-icon> PUBLIC</v-btn
          >
          <v-btn
            v-else
            v-bind="attrs"
            v-on="on"
            x-small
            color="success"
            dark
            class="mt-1 mr-0 ml-0 pr-1 pl-1"
            text
          >
            <v-icon x-small>mdi-arrow-up-thick</v-icon> Public</v-btn
          >
        </template>

        <v-chip v-if="url.aia" x-small color="primary" class="mt-1">AIA</v-chip>
        <v-chip v-if="url.sia" x-small color="primary" class="mt-1">SIA</v-chip>
        <v-chip v-if="url.crl" x-small color="primary" class="mt-1">CRL</v-chip>
        <v-chip v-if="url.ocsp" x-small color="primary" class="mt-1">
          OCSP
        </v-chip>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "Generic",
  props: ["url"],
  data: function() {
    return {
      remote: false,
      loading: false
    };
  },
  computed: {
    display_uptime: function() {
      if (this.url.is_private) {
        return 100;
      }
      if (this.url.thirty_day_uptime || this.url.thirty_day_uptime === 0) {
        return Math.round(this.url.thirty_day_uptime * 1000) / 10;
      } else if (this.url.public_thirty_day_uptime != null) {
        return Math.round(this.url.public_thirty_day_uptime * 1000) / 10;
      } else {
        return null;
      }
    },
    unavailable: function() {
      if (this.url.current_groups) {
        return (
          this.url.current_groups.filter(function(value) {
            return value.available === false;
          }).length > 0
        );
      } else {
        return null;
      }
    },
    public_groups: function() {
      if (this.url.current_groups) {
        return this.url.current_groups.filter(function(value) {
          return value.sensor === 2;
        });
      } else {
        return [];
      }
    },
    private_groups: function() {
      if (this.url.current_groups) {
        return this.url.current_groups.filter(function(value) {
          return value.sensor !== 2;
        });
      } else {
        return [];
      }
    },
    computed_color: function() {
      let time = this.display_uptime;
      if (time === 100) {
        return "#008000";
      } else if (time >= 99.99) {
        return "#91cf60";
      } else if (time >= 99.9) {
        return "#d9ef8b";
      } else if (time >= 99) {
        return "#fee08b";
      } else if (time >= 90) {
        return "#fc8d59";
      } else {
        return "#d73027";
      }
    }
  },
  mounted: function() {},
  methods: {
    copyText() {
      let textToCopy = this.$refs.textToCopy.$el.querySelector("input");
      textToCopy.select();
      document.execCommand("copy");
    }
  }
};
</script>

<style scoped></style>
