var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mb-2",staticStyle:{"font-size":".9em","min-height":"97px","width":"100%"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table_headers,"items":_vm.desserts,"options":_vm.options,"server-items-length":_vm.totalDesserts,"loading":_vm.loading,"sort-by":"valid_from","sort-desc":true,"footer-props":{
          'items-per-page-options': [5, 10, 25, 100]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.sha1",fn:function(ref){
        var item = ref.item;
return [_c('CertTile',{attrs:{"certificate":item,"local":_vm.local}})]}},{key:"item.subject_fqdn.friendly_name",fn:function(ref){
        var item = ref.item;
return [_c('Fqdn',{attrs:{"value":item.subject_fqdn,"size":"small"}}),_c('br'),_c('span',{staticStyle:{"font-size":".8em","font-weight":"bold","margin-left":"5px"}},[_vm._v("Issued by")]),_c('Fqdn',{attrs:{"value":item.issuer_fqdn,"size":"x-small"}})]}},{key:"item.valid_from",fn:function(ref){
        var item = ref.item;
return [(_vm.beforeNow(item.valid_from))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_from,"MMM D YYYY"))+" ")]):_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_from,"MMM D YYYY"))+" ")])]}},{key:"item.valid_to",fn:function(ref){
        var item = ref.item;
return [(_vm.afterNow(item.valid_to))?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_to,"MMM D YYYY"))+" ")]):_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.valid_to,"MMM D YYYY"))+" ")])]}},{key:"loading",fn:function(){return [_c('LoadingSVG')]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }