<template>
  <v-card style="font-size:.9em;min-height:97px;" class="mb-2">
    <v-data-table
      :headers="table_headers"
      :items="desserts"
      :options.sync="options"
      :server-items-length="totalDesserts"
      :loading="loading"
      class="elevation-1"
      sort-by="valid_from"
      :sort-desc="true"
      :footer-props="{
        'items-per-page-options': [5, 10, 25, 100]
      }"
    >
      <template v-slot:item.uri.uri="{ item }">
        <Generic :url="item"></Generic>
      </template>

      <template v-slot:loading>
        <LoadingSVG />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import moment from "moment";
import UserService from "../../store/UserService";
import LoadingSVG from "../LoadingSVG";
import Generic from "../uris/Generic";
export default {
  name: "SearchUris",
  components: { Generic, LoadingSVG },
  props: ["data_url", "search"],
  data: function() {
    return {
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: { itemsPerPage: 10 },
      table_headers: [{ text: "URL", sortable: true, value: "uri.uri" }]
    };
  },
  methods: {
    afterNow: function(time) {
      return moment(time).isAfter(moment());
    },
    beforeNow: function(time) {
      return moment(time).isBefore(moment());
    },
    get_display_uptime(uri) {
      if (this.loading || !uri) {
        return false;
      }
      if (uri.per_issuer_ocsp_uris) {
        let count = uri.per_issuer_ocsp_uris.length;
        let sum = uri.per_issuer_ocsp_uris
          .map(e => Math.max(e.thirty_day_uptime, e.public_thirty_day_uptime))
          .reduce((a, b) => a + b, null);
        console.log(sum);
        return sum / count;
      } else {
        return Math.max(uri.thirty_day_uptime, uri.public_thirty_day_uptime);
      }
    }
  },
  watch: {
    options: {
      handler() {
        let url =
          this.data_url +
          "&page=" +
          this.options.page +
          "&size=" +
          this.options.itemsPerPage;
        if (this.search) {
          url += "&search=" + this.search;
        }
        if (this.options.sortBy) {
          if (this.options.sortDesc[0]) {
            url += "&ordering=-" + this.options.sortBy;
          } else {
            url += "&ordering=" + this.options.sortBy;
          }
        }
        console.log(url);
        UserService.getLocalApi(url).then(response => {
          let items = response.data.results;

          this.totalDesserts = response.data.count;
          this.desserts = items.map(e => ({
            ...e,
            thirty_day_uptime: this.get_display_uptime(e)
          }));
          this.loading = false;
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped></style>
