<template>
  <MaterialCardMini>
    <template v-slot:heading>
      <v-card-title class="pa-0 pl-2" style="font-size:.85em;">
        Monitoring Groups
      </v-card-title>
    </template>
    <v-card-text class="ma-0 pa-0">
      <v-card flat>
        <v-card-text>
          <template v-for="item in monitored_groups">
            <div :key="item.id" v-if="item.group">
              <v-checkbox
                dense
                hide-details
                :label="item.group.name"
                @click="check_group(item)"
                v-model="selected_groups"
                v-bind:value="item.group.id"
              ></v-checkbox>

              <div
                style="border-left:1px solid #666;padding-left:20px;margin-left:12px;"
              >
                <template v-if="item.group.group_type === 1">
                  <template v-for="rule in item.group.ca_rules">
                    <v-checkbox
                      :key="rule.id"
                      v-model="selected_ca_rules"
                      v-bind:value="rule.id"
                      dense
                      hide-details
                      :label="rule.fqdn.friendly_name"
                    ></v-checkbox>
                  </template>
                </template>

                <template v-else>
                  <template v-for="rule in item.group.path_rules">
                    <v-checkbox
                      :key="rule.id"
                      v-model="selected_path_rules"
                      v-bind:value="rule.id"
                      dense
                      hide-details
                      :label="rule.name"
                    ></v-checkbox>
                  </template>
                </template>
              </div>
            </div>
          </template>
        </v-card-text>
      </v-card>
    </v-card-text>
  </MaterialCardMini>
</template>
<script>
import MaterialCardMini from "../charts/MaterialCardMini";
export default {
  name: "GroupFilter",
  components: { MaterialCardMini },
  props: [
    "group",
    "token",
    "default",
    "in_groups",
    "in_group_settings",
    "flat"
  ],
  data: function() {
    return {
      saving: false,
      saved: false,
      error: false,
      groups: [],
      groups_loading: true,
      group_settings_loading: true,
      group_settings: [],
      selected_groups: [],
      selected_ca_rules: [],
      selected_path_rules: [],
      initial_load: true
    };
  },
  computed: {
    selected_rules: function() {
      return { path: this.selected_path_rules, ca: this.selected_ca_rules };
    },
    monitored_groups: function() {
      // groups the user has settings for (main table)
      return this.$store.getters.monitored_groups;
    }
  },
  methods: {
    check_group: function(group) {
      if (this.selected_groups.includes(group.group.id)) {
        if (group.group.group_type === 1) {
          for (let key in group.group.ca_rules) {
            this.selected_ca_rules.push(group.group.ca_rules[key].id);
          }
        } else {
          for (let key in group.group.path_rules) {
            this.selected_path_rules.push(group.group.path_rules[key].id);
          }
        }
      } else {
        if (group.group.group_type === 1) {
          for (let key in group.group.ca_rules) {
            this.selected_ca_rules = this.selected_ca_rules.filter(
              item => item !== group.group.ca_rules[key].id
            );
          }
        } else {
          for (let key in group.group.path_rules) {
            this.selected_path_rules = this.selected_path_rules.filter(
              item => item !== group.group.path_rules[key].id
            );
          }
        }
      }
    },
    updateGroup: function() {},
    selectAll: function() {
      let self = this;
      for (let x in this.monitored_groups) {
        let group = this.monitored_groups[x];
        if (group.settings.length !== 0) {
          self.selected_groups.push(group.group.id);
          if (group.group.group_type === 1) {
            for (let key in group.group.ca_rules) {
              self.selected_ca_rules.push(group.group.ca_rules[key].id);
            }
          } else {
            for (let key in group.group.path_rules) {
              self.selected_path_rules.push(group.group.path_rules[key].id);
            }
          }
        }
      }
    }
  },
  created: function() {
    if (this.initial_load && this.default) {
      this.selectAll();
    }
  },
  watch: {
    monitored_groups: function() {
      /*
      if (
        !this.groups_loading &&
        !this.group_settings_loading &&
        this.initial_load &&
        this.default
      ) {
        console.log("I WON");
        this.selectAll();
      } else {
        console.log("me");
      }*/
    },
    selected_rules: {
      deep: true,
      handler() {
        this.$emit("filter-group-rules", this.selected_rules);
      }
    }
  }
};
</script>
