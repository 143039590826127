<template>
  <svg
    viewBox="-2 -5 90 46"
    width="100"
    height="50"
    style="margin-top:15px;"
    v-if="!goDark"
  >
    <path
      class="path3"
      fill="#e4e4e4"
      stroke="rgba(5, 55, 104,.6)"
      stroke-width="2"
      d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
    />
    <path
      class="path3"
      fill="#e4e4e4"
      stroke="rgba(113, 146, 176,.6)"
      stroke-width="2"
      d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
    />
  </svg>
  <svg
    viewBox="-2 -5 90 46"
    width="100"
    height="50"
    style="margin-top:15px;"
    v-else
  >
    <path
      class="path3"
      fill="#555"
      stroke="#7192B0"
      stroke-width="2"
      d="M 33.6 8.8 L 22.35 8.8 L 22.35 34.4 L 11.3 34.4 L 11.3 8.8 L 0 8.8 L 0 0 L 33.6 0 L 33.6 8.8 Z"
    />
    <path
      class="path3"
      fill="#555"
      stroke="#7192B0"
      stroke-width="2"
      d="M 79.15 34.4 L 68.2 34.4 L 68.2 22 Q 68.2 19.7 68.375 17.275 A 121.746 121.746 0 0 1 68.51 15.565 Q 68.567 14.898 68.627 14.307 A 57.598 57.598 0 0 1 68.75 13.2 Q 68.944 11.6 68.997 11.128 A 27.416 27.416 0 0 0 69 11.1 L 68.8 11.1 L 62.45 34.4 L 53.8 34.4 L 47.4 11.15 L 47.2 11.15 A 17.772 17.772 0 0 0 47.225 11.36 Q 47.292 11.905 47.475 13.225 A 47.654 47.654 0 0 1 47.586 14.087 Q 47.747 15.435 47.895 17.217 A 129.35 129.35 0 0 1 47.9 17.275 A 57.649 57.649 0 0 1 48.1 21.856 A 53.066 53.066 0 0 1 48.1 22 L 48.1 34.4 L 37.95 34.4 L 37.95 0 L 53.55 0 L 58.75 19.85 L 58.95 19.85 L 64.1 0 L 79.15 0 L 79.15 34.4 Z"
    />
  </svg>
</template>

<script>
export default {
  name: "LoadingSVG",
  data: function() {
    return {
      goDark: false
    };
  }
};
</script>

<style scoped></style>
